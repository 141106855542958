<template>
	<section class="news-details col" align-x="left" :key="id">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item to="/manager/news">焦點消息</el-breadcrumb-item>
			<el-breadcrumb-item>{{_news.title}}</el-breadcrumb-item>
		</el-breadcrumb>
		<br>
		<h1>{{_news.title}}</h1>
		<hr>
		<template v-if="!isNew">
			<br>
			<p>上傳縮圖</p>
			<el-card>
				<div class="row" gutter="10">
					<div>
						<upload-thumb v-model="thumb" />
					</div>
					<div class="row" align-self="bottom">
						<el-button @click="handleSave({ thumb })" type="success">儲存</el-button>
						<el-button @click="handleCancel('thumb')" type="info">取消</el-button>
					</div>
				</div>
			</el-card>
		</template>
		<br>
		<br>
		<el-card>
			<el-form :model="$data">
				<el-form-item prop="title" label="標題">
					<el-input v-model="title" type="textarea" :autosize="{minRows:3}" />
				</el-form-item>
				<el-form-item prop="writer" label="作者資訊">
					<el-input v-model="writer" placeholder="例：新活水 文／陳琡分．攝／林政億" />
				</el-form-item>
				<el-form-item prop="date" label="日期">
					<el-date-picker v-model="date" value-format="timestamp" />
				</el-form-item>
				<el-form-item>
					<div class="row" align-x="right">
						<el-button @click="handleSave({ title, writer, date })" type="success">儲存</el-button>
						<el-button @click="handleCancel(['title', 'writer', 'date'])" type="info">取消</el-button>
					</div>
				</el-form-item>
			</el-form>
		</el-card>
		<br>
		<br>
		<p>編輯內容</p>
		<el-card>
			<tinymce v-model="details" />
			<div class="center">
				<el-button @click="handleSave({ details })" type="success">儲存</el-button>
				<el-button @click="handleCancel('details')" type="info">取消</el-button>
			</div>
		</el-card>
		<template v-if="id && id != 'new'">
			<br>
			<br>
			<el-button @click="handleRemove()" type="danger" align-self="center">刪除此則消息</el-button>
		</template>
		<br>
	</section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import querystring from "querystring";
export default {
	components: {
		UploadThumb: () => import("@/components/upload-thumb"),
		Upload: () => import("@/components/upload"),
		Tinymce: () => import("@/components/tinymce"),
	},
	props: ["id"],
	data() {
		return {
			title: "",
			thumb: "",
			writer: "",
			date: "",
			details: "",
		}
	},
	computed: {
		...mapState("news", ["_news"]),
		isNew() {
			return this.id == "new";
		},
		youtubeId() {
			const { youtube } = this._news;
			return this.youtube && querystring.parse(new URL(this.youtube).searchParams.toString())["v"] || ""
		}
	},
	methods: {
		...mapActions("news", ["_getData", "_updateData", "_createData", "_removeData"]),
		async handleSave(data) {
			if (this.isNew) {
				const { id, message } = await this._createData(data);
				this.$message.success({ message });
				this.$router.push(`/manager/news/${id}`);
			}
			else this.$message.success(await this._updateData({
				id: this.id,
				data
			}));
		},
		handleCancel(key) {
			if (key.forEach) {
				key.forEach(k => {
					this.$set(this.$data, k, this._.cloneDeep(this._news[k]));
				});
			} else {
				this.$set(this.$data, key, this._.cloneDeep(this._news[key]));
			}
		},
		handleRemove() {
			this.$confirm(`確認要刪除「${this._news.title || "尚未命名"}」？`, { type: "warning" })
				.then(async () => {
					this.$message.success(await this._removeData(this.id));
					this.$router.push("/manager/news");
				});
		}
	},
	async created() {
		if (!this.isNew) {
			await this._getData(this.id);
			this._.assign(this.$data, this._.cloneDeep(this._news));
		}
	}
}
</script>